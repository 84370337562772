import { ref } from "vue";
import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useRouter } from "vue-router";

const USER_KEY = "user_data" as string;

export interface User {
  name: string;
  employee_id: string;
  email: string;
  password: string;
  api_token: string;
  permissions: string[];
}

type UserData = {
  token: string;
  id: number;
  employee_id: number;
  name: string;
  email: string;
  telegram_id: string;
  alerts_by: string;
  permissions: string[];
  activated: boolean;
};

export const useAuthStore = defineStore("auth", () => {
  const errors = ref({});
  const user = ref<UserData>({} as UserData);
  const isAuthenticated = ref(!!JwtService.getToken());
  const router = useRouter();

  const setItemWithExpiry = (key, value) => {
      const now = new Date();
      // Set the expiration time for 3 days (in milliseconds)
      const expiryTime = now.getTime() + 5 * 60 * 60 * 1000; 

      const item = {
          value: value,
          expiry: expiryTime,
      };

      localStorage.setItem(key, JSON.stringify(item));
  }

  const getItemWithExpiry = (key) => {
      const itemStr = localStorage.getItem(key);
      
      if (!itemStr) {
          return null;
      }

      const item = JSON.parse(itemStr);
      const now = new Date();

      if (now.getTime() > item.expiry) {
          localStorage.removeItem(key);
          return null;
      }

      return item.value;
  }

  const destroyUserData = (): void => {
    window.localStorage.removeItem(USER_KEY);
  };

  function setAuth(userData: UserData) {
    isAuthenticated.value = true;
    user.value = structuredClone(userData);
    setItemWithExpiry(USER_KEY, user.value);
    errors.value = {};
    JwtService.saveToken(user.value.token);
    if (!user.value.permissions) {
      user.value.permissions = [];
    }
    setPermissions();
  }

  function setError(error: any) {
    errors.value = { ...error, login: false };
  }

  function purgeAuth() {
    isAuthenticated.value = false;
    user.value = {} as UserData;
    errors.value = [];
    JwtService.destroyToken();
    destroyUserData();
  }

  function cannot(permission: string | null = null) {
    if (user.value.permissions && permission) {
      return !user.value.permissions.includes(permission);
    }
    return false;
  }

  function getUser(fn: any, redirect: boolean = true) {
    // if (Object.keys(user.value).length === 0) {
    const value = getItemWithExpiry(USER_KEY);
    verifyUser();
    if ((value === null || Object.keys(value).length === 0)) {
      purgeAuth();
      if (redirect) {
        router.push("/sign-in")
      }
      return;
    }
    fn(value);
  }

  function login(credentials: User) {
    let deactivated = false;
    return ApiService.post("/auth/login", credentials)
      .then(({ data }) => {
        if (data.data.activated == 0) {
          purgeAuth();
          deactivated = true;
          throw new Error();
        }
        setAuth(data.data);
      })
      .catch(({ response }) => {
        if (deactivated) {
          router.replace({ path: "/sign-in", query: { deactivated: '1' } });
          return;
        }
        setError(response ? response.data : "error");
      });
  }

  function logout() {
    purgeAuth();
  }

  // function setPermissions(fn) {
  function setPermissions() {
    ApiService.setHeader();
    ApiService.get("/getuserpermissions")
      .then(({ data }) => {
        user.value.permissions = data.data;
        // fn(user.value);
      });
  }

  function register(credentials: User) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        setAuth(data);
      })
      .catch(({ response }) => {
        setError(response.data.errors);
      });
  }

  function forgotPassword(email: string) {
    return ApiService.post("forgot_password", email)
      .then(() => {
        setError({});
      })
      .catch(({ response }) => {
        setError(response.data.errors);
      });
  }

  // function verifyAuth() {
  //   let output = true;
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();
  //     ApiService.get("/me")
  //       .then(({ data }) => {
  //         if (data.data.activated == 0) {
  //           purgeAuth();
  //           router.push("/sign-in?deactivated=1")
  //           return;
  //         }
  //         user.value = data.data;
  //         if (!user.value.permissions) {
  //           user.value.permissions = [];
  //         }
  //         setPermissions();
  //       })
  //       .catch(({ response }) => {
  //         setError(response.data.errors);
  //         purgeAuth();
  //         output = false;
  //       });
  //   } else {
  //     purgeAuth();
  //     output = false;
  //   }
  //   return output;
  // }

  function verifyUser() {
    ApiService.setHeader();
    ApiService.get("/me")
      .then(({ data }) => {
      })
      .catch(({ response }) => {
        setError(response.data.errors);
        purgeAuth();
        router.push("/sign-in");
      });
  }

  function verifyAuth() {
    if (user.value.permissions == null || (Array.isArray(user.value.permissions) && user.value.permissions.length === 0)) {
      setPermissions();
    }
    if (JwtService.getToken()) {
      return true;
    }
    purgeAuth();
    return false;
  }

  return {
    errors,
    user,
    isAuthenticated,
    login,
    logout,
    register,
    forgotPassword,
    verifyAuth,
    cannot,
    getUser,
  };
});
