import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { useConfigStore } from "@/stores/config";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/default-layout/DefaultLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboard"],
        },
      },
      {
        path: "/production",
        name: "production",
        component: () => import("@/views/production/Production.vue"),
        meta: {
          breadcrumbs: ["Produção"],
        },
        children: [
          {
            path: "/planning",
            name: "production-planning",
            component: () => import("@/views/production/Planning.vue"),
            meta: {
              permission: "assign tasks",
              pageTitle: "Planeamento",
              breadcrumbs: ["Produção", "Planeamento"],
            },
          },
          // {
          //   path: "/receipts",
          //   name: "print-item-planning",
          //   component: () => import("@/views/production/Print.vue"),
          //   props: (route) => ({
          //     _receipts: route.query.receipts,
          //   }),
          //   meta: {
          //     hideHeader: true,
          //     hideMenu: true,
          //     permission: "assign tasks",
          //     pageTitle: "Etiqueta",
          //     breadcrumbs: ["Produção", "Planeamento", "Etiqueta"],
          //   },
          // },
        ],
      },
      {
        path: "/settings/:tab?",
        name: "settings",
        component: () => import("@/views/Settings.vue"),
        props: true,
        meta: {
          permission: "modify settings",
          pageTitle: "Configurações",
          breadcrumbs: ["Configurações"],
        },
      },
      {
        path: "/suppliers/order/:type",
        name: "order-items",
        component: () => import("@/views/suppliers/Order.vue"),
        props: (route) => ({
          auth_token: route.query.auth_token,
          type: route.params.type,
        }),
        meta: {
          permission: "order from supplier",
          pageTitle: "Encomendar",
          breadcrumbs: ["Fornecedores", "Encomendar"],
        },
      },
      {
        path: "/suppliers/order/weekly/:type",
        name: "order-items-weekly",
        component: () => import("@/views/suppliers/GlassOrder.vue"),
        props: (route) => ({
          auth_token: route.query.auth_token,
          type: route.params.type,
        }),
        meta: {
          permission: "order from supplier",
          pageTitle: "Encomendar",
          breadcrumbs: ["Fornecedores", "Encomendar"],
        },
      },
      {
        path: "/reports/clients",
        name: "reports-clients",
        component: () => import("@/views/reports/clients/All.vue"),
        props: (route) => ({
          country: route.query.country,
        }),
        meta: {
          pageTitle: "Relações de Cargas",
          breadcrumbs: ["Relatórios", "Cargas"],
        },
      },
      {
        path: "/reports/assists",
        name: "reports-assists",
        component: () => import("@/views/reports/assists/All.vue"),
        props: (route) => {},
        meta: {
          pageTitle: "Relações de Assistências",
          breadcrumbs: ["Relatórios", "Assistências"],
        },
      },
      {
        path: "/reports/suppliers",
        name: "reports-suppliers",
        component: () => import("@/views/reports/suppliers/Suppliers.vue"),
        meta: {
          pageTitle: "Relatórios de Fornecedores",
          breadcrumbs: ["Relatórios", "Fornecedores"],
        },
      },
      {
        path: "/reports/suppliers/:supplier",
        name: "reports-suppliers-orders",
        component: () => import("@/views/reports/suppliers/All.vue"),
        props: true,
        meta: {
          pageTitle: "Relatórios de Fornecedores",
          breadcrumbs: ["Relatórios", "Fornecedores", "Encomendas"],
        },
      },
      {
        path: "/reports/items",
        name: "reports-items",
        component: () => import("@/views/reports/items/All.vue"),
        meta: {
          pageTitle: "Relatórios de encomendas",
          breadcrumbs: ["Relatórios", "Encomendas"],
        },
      },
      {
        path: "/reports/items/overview",
        name: "overview-item",
        component: () => import("@/views/reports/items/Overview.vue"),
        props: (route) => ({
          _index: route.query.index,
          _page: route.query.page,
          _order: route.query.order,
          _orderBy: route.query.order_by,
          _filters: route.query.filters,
        }),
        meta: {
          pageTitle: "Encomenda",
          breadcrumbs: ["Relatórios", "Encomendas", "Detalhes de Encomenda"],
        },
      },
      {
        path: "/reports/employees",
        name: "reports-employees",
        component: () => import("@/views/reports/employees/All.vue"),
        meta: {
          pageTitle: "Relatórios de Funcionários",
          breadcrumbs: ["Relatórios", "Funcionários"],
        },
      },
      {
        path: "/reports/employees/:employeeId",
        name: "overview-employee",
        component: () => import("@/views/reports/employees/Overview.vue"),
        meta: {
          pageTitle: "Funcionário",
          breadcrumbs: ["Relatórios", "Funcionários", "Detalhes de Funcionário"],
        },
      },
      // {
      //   path: "/profile",
      //   name: "profile",
      //   component: () => import("@/components/page-layouts/Profile.vue"),
      //   meta: {
      //     breadcrumbs: ["Pages", "Profile"],
      //   },
      //   children: [
      //     {
      //       path: "overview",
      //       name: "profile-overview",
      //       component: () =>
      //         import("@/views/profile/Overview.vue"),
      //       meta: {
      //         pageTitle: "Overview",
      //       },
      //     },
      //     {
      //       path: "projects",
      //       name: "profile-projects",
      //       component: () =>
      //         import("@/views/profile/Projects.vue"),
      //       meta: {
      //         pageTitle: "Projects",
      //       },
      //     },
      //     {
      //       path: "campaigns",
      //       name: "profile-campaigns",
      //       component: () =>
      //         import("@/views/profile/Campaigns.vue"),
      //       meta: {
      //         pageTitle: "Campaigns",
      //       },
      //     },
      //     {
      //       path: "documents",
      //       name: "profile-documents",
      //       component: () =>
      //         import("@/views/profile/Documents.vue"),
      //       meta: {
      //         pageTitle: "Documents",
      //       },
      //     },
      //     {
      //       path: "connections",
      //       name: "profile-connections",
      //       component: () =>
      //         import("@/views/profile/Connections.vue"),
      //       meta: {
      //         pageTitle: "Connections",
      //       },
      //     },
      //     {
      //       path: "activity",
      //       name: "profile-activity",
      //       component: () =>
      //         import("@/views/profile/Activity.vue"),
      //       meta: {
      //         pageTitle: "Activity",
      //       },
      //     },
      //   ],
      // },
      {
        path: "wizards/vertical",
        name: "vertical-wizard",
        component: () =>
          import("@/views/wizards/VerticalWizardPage.vue"),
        meta: {
          pageTitle: "Vertical",
          breadcrumbs: ["Pages", "Wizard"],
        },
      },
      // {
      //   path: "/modals/wizards/two-factor-auth",
      //   name: "modals-wizards-two-factor-auth",
      //   component: () =>
      //     import("@/views/modals/wizards/TwoFactorAuth.vue"),
      //   meta: {
      //     pageTitle: "Two Factory Auth",
      //     breadcrumbs: ["Crafted", "Modals", "Wizards"],
      //   },
      // },
      // {
      //   path: "/widgets/lists",
      //   name: "widgets-list",
      //   component: () => import("@/views/widgets/Lists.vue"),
      //   meta: {
      //     pageTitle: "Lists",
      //     breadcrumbs: ["Crafted", "Widgets"],
      //   },
      // },
      // {
      //   path: "/widgets/statistics",
      //   name: "widgets-statistics",
      //   component: () => import("@/views/widgets/Statistics.vue"),
      //   meta: {
      //     pageTitle: "Statistics",
      //     breadcrumbs: ["Crafted", "Widgets"],
      //   },
      // },
      // {
      //   path: "/widgets/charts",
      //   name: "widgets-charts",
      //   component: () => import("@/views/widgets/Charts.vue"),
      //   meta: {
      //     pageTitle: "Charts",
      //     breadcrumbs: ["Crafted", "Widgets"],
      //   },
      // },
      // {
      //   path: "/widgets/mixed",
      //   name: "widgets-mixed",
      //   component: () => import("@/views/widgets/Mixed.vue"),
      //   meta: {
      //     pageTitle: "Mixed",
      //     breadcrumbs: ["Crafted", "Widgets"],
      //   },
      // },
      // {
      //   path: "/widgets/tables",
      //   name: "widgets-tables",
      //   component: () => import("@/views/widgets/Tables.vue"),
      //   meta: {
      //     pageTitle: "Tables",
      //     breadcrumbs: ["Crafted", "Widgets"],
      //   },
      // },
      // {
      //   path: "/widgets/feeds",
      //   name: "widgets-feeds",
      //   component: () => import("@/views/widgets/Feeds.vue"),
      //   meta: {
      //     pageTitle: "Feeds",
      //     breadcrumbs: ["Crafted", "Widgets"],
      //   },
      // },
      // {
      //   path: "/modals/forms/new-target",
      //   name: "modals-forms-new-target",
      //   component: () => import("@/views/modals/forms/NewTarget.vue"),
      //   meta: {
      //     pageTitle: "New Target",
      //     breadcrumbs: ["Crafted", "Modals", "Forms"],
      //   },
      // },
      // {
      //   path: "/modals/forms/new-card",
      //   name: "modals-forms-new-card",
      //   component: () => import("@/views/modals/forms/NewCard.vue"),
      //   meta: {
      //     pageTitle: "New Card",
      //     breadcrumbs: ["Crafted", "Modals", "Forms"],
      //   },
      // },
      // {
      //   path: "/modals/forms/new-address",
      //   name: "modals-forms-new-address",
      //   component: () => import("@/views/modals/forms/NewAddress.vue"),
      //   meta: {
      //     pageTitle: "New Address",
      //     breadcrumbs: ["Crafted", "Modals", "Forms"],
      //   },
      // },
      // {
      //   path: "/modals/forms/create-api-key",
      //   name: "modals-forms-create-api-key",
      //   component: () =>
      //     import("@/views/modals/forms/CreateApiKey.vue"),
      //   meta: {
      //     pageTitle: "Create Api Key",
      //     breadcrumbs: ["Crafted", "Modals", "Forms"],
      //   },
      // },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/receipts",
        name: "print-item-planning",
        component: () => import("@/views/production/Print.vue"),
        props: (route) => ({
          _receipts: route.query.receipts,
        }),
        meta: {
          hideHeader: true,
          hideMenu: true,
          hideAll: true,
          // permission: "assign tasks",
          pageTitle: "Etiqueta",
          breadcrumbs: ["Produção", "Planeamento", "Etiqueta"],
        },
      },
      {
        path: "/doc_header",
        name: "doc_header_print",
        component: () => import("@/views/production/Doc.vue"),
        props: (route) => ({
          serie: route.query.serie,
          serie_number: route.query.serie_number,
          pin: route.query.pin,
        }),
        meta: {
          hideAll: false,
          // permission: "assign tasks",
          pageTitle: "Documento",
          breadcrumbs: ["Documento"],
        },
      },
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/authentication/basic-flow/SignIn.vue"),
        props: (route) => ({
          deactivated: route.query.deactivated || 0,
        }),
        meta: {
          pageTitle: "Sign In",
        },
      },
      // {
      //   path: "/sign-up",
      //   name: "sign-up",
      //   component: () =>
      //     import("@/views/authentication/basic-flow/SignUp.vue"),
      //   meta: {
      //     pageTitle: "Sign Up",
      //   },
      // },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        path: "/pickings",
        name: "pickings",
        component: () => import("@/views/PickingsPage.vue"),
        meta: {
          // hideHeader: true,
          pageTitle: "Picagem",
          breadcrumbs: ["Picagem"],
        },
      },
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    // If the route has a hash, scroll to the section with the specified ID; otherwise, scroll to the top of the page.
    if (to.hash) {
      return {
        el: to.hash,
        top: 80,
        behavior: "smooth",
      };
    } else {
      return {
        top: 0,
        left: 0,
        behavior: "smooth",
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const configStore = useConfigStore();

  // current page view title
  document.title = `${to.meta.pageTitle} - ${import.meta.env.VITE_APP_NAME}`;

  // reset config to initial state
  configStore.resetLayoutConfig();

  // verify auth token before each page change
  // console.log(authStore.verifyAuth());

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (authStore.verifyAuth()) {
      if (authStore.cannot(to.meta.permission)) {
        next({ name: "dashboard" });
        return;
      }
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }
});

export default router;
